import React from "react";

import Dropdown from "react-bootstrap/Dropdown";
import { Link, useMatch } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import logo from "../assets/logo.png"
import searchLogo from "../assets/screensearch.png"
import rankingLogo from "../assets/ranking.png"
import settingsLogo from "../assets/settings.png"
import diversityLogo from "../assets/diversity.png"
import barWadgetLogo from "../assets/barwidget.png"
import logOutIMageIcon from "../assets/logoOutIconst.png"

function BarSide2() {
  const handleLogout = () => {
    localStorage.removeItem("jsonToken"); // Remove the token
    localStorage.removeItem("userData");
    window.location.href = "/"; // Redirect to the home page
  };

  const matchKwResearch = useMatch("/kwresearch");
  const matchRankTracker = useMatch("/ranktracker");
  const matchSettings = useMatch("/settings");
  const projectsSettings = useMatch("/projects");
  const subRedditArticlePoster = useMatch("/subredditarticleposter");
  const mediumPoster = useMatch("/mediumDetailsSubReddit");
  const clusterPoster = useMatch("/cluster");
  const clusterPosterDy = useMatch("/keyword/:keywordId");
  const jobStatus = useMatch("/job-status");
  const matchDashboard = useMatch("/");
  const matchKeywordDetail = useMatch("/keyword-detail/:mainSeedKeyword");
  const matchRankingDetail = useMatch("/ranking-details/:keyword");
  const matchProjectDetail = useMatch("/project/:projectName");
  const mediumPosterProject = useMatch("/mediumPosterMain/:projectId");
  const matchProjectDetailSubreddit = useMatch("/projectsubreddit/:projectId");

  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;


  return (
    <div
      className="sticky d-flex flex-column justify-content-center align-items-center flex-shrink-0 "
      style={{
        width: "6rem",
        borderRight: "solid 1px #efefef",
        height: "100vh",
        // background: "linear-gradient(0deg,rgba(105,145,214,.08),rgba(105,145,214,.08)),#fff"
        background: "rgb(247, 248, 250)",
      }}
    >
      <Link
        to="/"
        className="d-block p-3 link-body-emphasis text-decoration-none navbar-brand mb-1"
      >
        <div>
          
          <img src={logo} />
        </div>
      </Link>

      <ul className="nav nav-pills nav-flush flex-column mb-auto text-center gap-1">
  {userData ? (
    <>
      {userData.isRedditTool && userData.isTopicalMappingTool ? (
        <>
          <li className="nav-item">
            <Link to="/projects" className="flex flex-col items-center justify-center navatag">
              <div className="flex items-center justify-center rounded-2xl">
                <span className={`material-symbols-outlined spantabnavbar ${projectsSettings || matchProjectDetail ? "spantabnarvaractive" : ""}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="M240-120q-66 0-113-47T80-280q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm480 0q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm-480-80q33 0 56.5-23.5T320-280q0-33-23.5-56.5T240-360q-33 0-56.5 23.5T160-280q0 33 23.5 56.5T240-200Zm480 0q33 0 56.5-23.5T800-280q0-33-23.5-56.5T720-360q-33 0-56.5 23.5T640-280q0 33 23.5 56.5T720-200ZM480-520q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560-680q0-33-23.5-56.5T480-760q-33 0-56.5 23.5T400-680q0 33 23.5 56.5T480-600Zm0-80Zm240 400Zm-480 0Z"/>
                  </svg>
                </span>
              </div>
              <p className="ptagnavbar">Traffic Ops</p>
            </Link>
          </li>
           
          <li className="nav-item">
            <Link to="/cluster" className="flex flex-col items-center justify-center navatag">
              <div className="flex items-center justify-center rounded-2xl">
                <span className={`material-symbols-outlined spantabnavbar ${clusterPoster || clusterPosterDy ? "spantabnarvaractive" : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
                <path d="M280-120q-83 0-141.5-58.5T80-320q0-73 45.5-127.5T240-516v83q-35 12-57.5 43T160-320q0 50 35 85t85 35q50 0 85-35t35-85v-40h235q8-9 19.5-14.5T680-380q25 0 42.5 17.5T740-320q0 25-17.5 42.5T680-260q-14 0-25.5-5.5T635-280H476q-14 69-68.5 114.5T280-120Zm400 0q-56 0-101.5-27.5T507-220h107q14 10 31 15t35 5q50 0 85-35t35-85q0-50-35-85t-85-35q-20 0-37 5.5T611-418L489-621q-21-4-35-20t-14-39q0-25 17.5-42.5T500-740q25 0 42.5 17.5T560-680v8.5q0 3.5-2 8.5l87 146q8-2 17-2.5t18-.5q83 0 141.5 58.5T880-320q0 83-58.5 141.5T680-120ZM280-260q-25 0-42.5-17.5T220-320q0-22 14-38t34-21l94-156q-29-27-45.5-64.5T300-680q0-83 58.5-141.5T500-880q83 0 141.5 58.5T700-680h-80q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 43 26 75.5t66 41.5L337-338q2 5 2.5 9t.5 9q0 25-17.5 42.5T280-260Z"/>
                </svg>
                 </span>
              </div>
              <p className="ptagnavbar">Topical Mapping Tool</p>
            </Link>
          </li>
        </>
      ) : userData.isRedditTool ? (
        <>
          <li className="nav-item">
            <Link to="/projects" className="flex flex-col items-center justify-center navatag">
              <div className="flex items-center justify-center rounded-2xl">
                <span className={`material-symbols-outlined spantabnavbar ${projectsSettings || matchProjectDetail ? "spantabnarvaractive" : ""}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="M240-120q-66 0-113-47T80-280q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm480 0q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm-480-80q33 0 56.5-23.5T320-280q0-33-23.5-56.5T240-360q-33 0-56.5 23.5T160-280q0 33 23.5 56.5T240-200Zm480 0q33 0 56.5-23.5T800-280q0-33-23.5-56.5T720-360q-33 0-56.5 23.5T640-280q0 33 23.5 56.5T720-200ZM480-520q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560-680q0-33-23.5-56.5T480-760q-33 0-56.5 23.5T400-680q0 33 23.5 56.5T480-600Zm0-80Zm240 400Zm-480 0Z"/>
                  </svg>
                </span>
              </div>
              <p className="ptagnavbar">Traffic Ops</p>
            </Link>
          </li>
        </>
      ) : userData.isTopicalMappingTool ? (
        <>
          
          <li className="nav-item">
            <Link to="/cluster" className="flex flex-col items-center justify-center navatag">
              <div className="flex items-center justify-center rounded-2xl">
                <span className={`material-symbols-outlined spantabnavbar ${clusterPoster || clusterPosterDy ? "spantabnarvaractive" : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
                <path d="M280-120q-83 0-141.5-58.5T80-320q0-73 45.5-127.5T240-516v83q-35 12-57.5 43T160-320q0 50 35 85t85 35q50 0 85-35t35-85v-40h235q8-9 19.5-14.5T680-380q25 0 42.5 17.5T740-320q0 25-17.5 42.5T680-260q-14 0-25.5-5.5T635-280H476q-14 69-68.5 114.5T280-120Zm400 0q-56 0-101.5-27.5T507-220h107q14 10 31 15t35 5q50 0 85-35t35-85q0-50-35-85t-85-35q-20 0-37 5.5T611-418L489-621q-21-4-35-20t-14-39q0-25 17.5-42.5T500-740q25 0 42.5 17.5T560-680v8.5q0 3.5-2 8.5l87 146q8-2 17-2.5t18-.5q83 0 141.5 58.5T880-320q0 83-58.5 141.5T680-120ZM280-260q-25 0-42.5-17.5T220-320q0-22 14-38t34-21l94-156q-29-27-45.5-64.5T300-680q0-83 58.5-141.5T500-880q83 0 141.5 58.5T700-680h-80q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 43 26 75.5t66 41.5L337-338q2 5 2.5 9t.5 9q0 25-17.5 42.5T280-260Z"/>
                </svg>
                 </span>
              </div>
              <p className="ptagnavbar">Topical Mapping Tool</p>
            </Link>
          </li>
        </>
      ) : (
        <>
          <li className="nav-item">
            <Link to="/" className="flex flex-col items-center justify-center navatag">
              <div className="flex items-center justify-center rounded-2xl">
                <span className={`material-symbols-outlined spantabnavbar ${matchDashboard ? "spantabnarvaractive" : ""}`}>
                  <img src={barWadgetLogo} alt="bar wadthh" />
                </span>
              </div>
              <p className="ptagnavbar">Dashboard</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/kwresearch" className="flex flex-col items-center justify-center navatag">
              <div className="flex items-center justify-center rounded-2xl">
                <span className={`material-symbols-outlined spantabnavbar ${matchKwResearch || matchKeywordDetail || jobStatus ? "spantabnarvaractive" : ""}`}>
                  <img src={searchLogo} alt="screen_search_desktop" />
                </span>
              </div>
              <p className="ptagnavbar">Keywords</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/ranktracker" className="flex flex-col items-center justify-center navatag">
              <div className="flex items-center justify-center rounded-2xl">
                <span className={`material-symbols-outlined spantabnavbar ${matchRankTracker || matchRankingDetail ? "spantabnarvaractive" : ""}`}>
                  <img src={rankingLogo} alt="monitoring" />
                </span>
              </div>
              <p className="ptagnavbar">Rank Tracker</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/projects" className="flex flex-col items-center justify-center navatag">
              <div className="flex items-center justify-center rounded-2xl">
                <span className={`material-symbols-outlined spantabnavbar ${projectsSettings || matchProjectDetail ? "spantabnarvaractive" : ""}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="M240-120q-66 0-113-47T80-280q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm480 0q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm-480-80q33 0 56.5-23.5T320-280q0-33-23.5-56.5T240-360q-33 0-56.5 23.5T160-280q0 33 23.5 56.5T240-200Zm480 0q33 0 56.5-23.5T800-280q0-33-23.5-56.5T720-360q-33 0-56.5 23.5T640-280q0 33 23.5 56.5T720-200ZM480-520q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560-680q0-33-23.5-56.5T480-760q-33 0-56.5 23.5T400-680q0 33 23.5 56.5T480-600Zm0-80Zm240 400Zm-480 0Z"/>
                  </svg>
                </span>
              </div>
              <p className="ptagnavbar">Traffic Ops</p>
            </Link>
          </li>
            {/* cluster */}
      <li className="nav-item">
            <Link to="/cluster" className="flex flex-col items-center justify-center navatag">
              <div className="flex items-center justify-center rounded-2xl">
                <span className={`material-symbols-outlined spantabnavbar ${clusterPoster || clusterPosterDy ? "spantabnarvaractive" : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
                <path d="M280-120q-83 0-141.5-58.5T80-320q0-73 45.5-127.5T240-516v83q-35 12-57.5 43T160-320q0 50 35 85t85 35q50 0 85-35t35-85v-40h235q8-9 19.5-14.5T680-380q25 0 42.5 17.5T740-320q0 25-17.5 42.5T680-260q-14 0-25.5-5.5T635-280H476q-14 69-68.5 114.5T280-120Zm400 0q-56 0-101.5-27.5T507-220h107q14 10 31 15t35 5q50 0 85-35t35-85q0-50-35-85t-85-35q-20 0-37 5.5T611-418L489-621q-21-4-35-20t-14-39q0-25 17.5-42.5T500-740q25 0 42.5 17.5T560-680v8.5q0 3.5-2 8.5l87 146q8-2 17-2.5t18-.5q83 0 141.5 58.5T880-320q0 83-58.5 141.5T680-120ZM280-260q-25 0-42.5-17.5T220-320q0-22 14-38t34-21l94-156q-29-27-45.5-64.5T300-680q0-83 58.5-141.5T500-880q83 0 141.5 58.5T700-680h-80q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 43 26 75.5t66 41.5L337-338q2 5 2.5 9t.5 9q0 25-17.5 42.5T280-260Z"/>
                </svg>
                 </span>
              </div>
              <p className="ptagnavbar">Topical Mapping Tool</p>
            </Link>
          </li>
      {/* cluster */}
           {/* medium */}
 
      <li className="nav-item">
            <Link to="/mediumDetailsSubReddit" className="flex flex-col items-center justify-center navatag">
              <div className="flex items-center justify-center rounded-2xl">
                <span className={`material-symbols-outlined spantabnavbar ${mediumPoster || mediumPosterProject ? "spantabnarvaractive" : ""}`}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px">
                <path d="M554-120q-54 0-91-37t-37-89q0-76 61.5-137.5T641-460q-3-36-18-54.5T582-533q-30 0-65 25t-83 82q-78 93-114.5 121T241-277q-51 0-86-38t-35-92q0-54 23.5-110.5T223-653q19-26 28-44t9-29q0-7-2.5-10.5T250-740q-10 0-25 12.5T190-689l-70-71q32-39 65-59.5t65-20.5q46 0 78 32t32 80q0 29-15 64t-50 84q-38 54-56.5 95T220-413q0 17 5.5 26.5T241-377q10 0 17.5-5.5T286-409q13-14 31-34.5t44-50.5q63-75 114-107t107-32q67 0 110 45t49 123h99v100h-99q-8 112-58.5 178.5T554-120Zm2-100q32 0 54-36.5T640-358q-46 11-80 43.5T526-250q0 14 8 22t22 8Zm244-460q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Z"/>
                </svg>
                 </span>
              </div>
              <p className="ptagnavbar">Medium Poster</p>
            </Link>
          </li>
      {/* medium */}
    
          <li className="nav-item">
            <Link to="/subredditarticleposter" className="flex flex-col items-center justify-center navatag">
              <div className="flex items-center justify-center rounded-2xl">
                <span className={`material-symbols-outlined spantabnavbar ${subRedditArticlePoster || matchProjectDetailSubreddit ? "spantabnarvaractive" : ""}`}>
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="M360-120q-66 0-113-47t-47-113v-327q-35-13-57.5-43.5T120-720q0-50 35-85t85-35q50 0 85 35t35 85q0 39-22.5 69.5T280-607v327q0 33 23.5 56.5T360-200q33 0 56.5-23.5T440-280v-400q0-66 47-113t113-47q66 0 113 47t47 113v327q35 13 57.5 43.5T840-240q0 50-35 85t-85 35q-50 0-85-35t-35-85q0-39 22.5-70t57.5-43v-327q0-33-23.5-56.5T600-760q-33 0-56.5 23.5T520-680v400q0 66-47 113t-113 47ZM240-680q17 0 28.5-11.5T280-720q0-17-11.5-28.5T240-760q-17 0-28.5 11.5T200-720q0 17 11.5 28.5T240-680Zm480 480q17 0 28.5-11.5T760-240q0-17-11.5-28.5T720-280q-17 0-28.5 11.5T680-240q0 17 11.5 28.5T720-200ZM240-720Zm480 480Z"/>
                  </svg>
                </span>
              </div>
              <p className="ptagnavbar">Subreddit Article Poster</p>
            </Link>
          </li>
        </>
      )}
      
      <li className="nav-item">
        <Link to="/settings" className="flex flex-col items-center justify-center px-0 gap-1">
          <div className="flex items-center justify-center rounded-2xl">
            <span className={`material-symbols-outlined spantabnavbar ${matchSettings ? "spantabnarvaractive" : ""}`}>
              <img src={settingsLogo} alt="settings" />
            </span>
          </div>
          <p className="ptagnavbar">Settings</p>
        </Link>
      </li>
     
    </>
  ) : (
    <>
      <li className="nav-item">
        <Link to="/" className="flex flex-col items-center justify-center navatag">
          <div className="flex items-center justify-center rounded-2xl">
            <span className={`material-symbols-outlined spantabnavbar ${matchDashboard ? "spantabnarvaractive" : ""}`}>
              <img src={barWadgetLogo} alt="bar wadthh" />
            </span>
          </div>
          <p className="ptagnavbar">Dashboard</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/kwresearch" className="flex flex-col items-center justify-center navatag">
          <div className="flex items-center justify-center rounded-2xl">
            <span className={`material-symbols-outlined spantabnavbar ${matchKwResearch || matchKeywordDetail || jobStatus ? "spantabnarvaractive" : ""}`}>
              <img src={searchLogo} alt="screen_search_desktop" />
            </span>
          </div>
          <p className="ptagnavbar">Keywords</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/ranktracker" className="flex flex-col items-center justify-center navatag">
          <div className="flex items-center justify-center rounded-2xl">
            <span className={`material-symbols-outlined spantabnavbar ${matchRankTracker || matchRankingDetail ? "spantabnarvaractive" : ""}`}>
              <img src={rankingLogo} alt="monitoring" />
            </span>
          </div>
          <p className="ptagnavbar">Rank Tracker</p>
        </Link>
      </li>
    </>
  )}
</ul>


      
            <div>
            {/* <span class="material-symbols-outlined logouticon" onClick={handleLogout}>
                  logout
                  </span> */}
            <img src={logOutIMageIcon} className="logouticon" alt="logout image" onClick={handleLogout} />
            </div>
    </div>
  );
}

export default BarSide2;
