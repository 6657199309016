import React, {useRef, useState, useEffect, useContext} from 'react'
// import Rightsidebar from '../components/rightsidebar';
import BarSide from '../components/BarSide';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {Tooltip} from "react-bootstrap";
import axios from "../components/Axios"
import { useGetValueContext  } from "../auth/GetValueContext";
// import ProSideBar from '../components/ProSideBar';
import BarSide2 from '../components/BarSide2';
import Accordionmain from '../components/Accordionmain';
// import { InfinityOutlined } from '@ant-design/icons';
import listLogo from "../assets/viewList.png"
import moreLogo from "../assets/more.png"
import infinateyLogo from "../assets/infin.png"
import { Link, useNavigate } from "react-router-dom";
import { KeywordContext } from "../auth/KeywordContext";


function Home() {
    const { creditsCount, setCreditsCount } = useGetValueContext();
    const isFetching = useRef(false);
    // const [credits, setCredits] = useState(0);
    const userDataString = localStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const [recentKeyword, setRecentData] = useState(null);
    // console.log(recentKeyword, "recentKeyword userkeywords latestuserkeywords")
  
   
    const fetchrecentKeywords = async (userId) => {
      const timestamp = new Date().getTime();
      try {
        const response = await axios.get(`/api/latestuserkeywords/${userId}?_=${timestamp}`);
        setRecentData(response.data);
      } catch (error) {
        console.error('Error fetching user keywords', error);
      }
    };



    const fetchCredits = async () => {
        if (!isFetching.current) {
          isFetching.current = true;
          try {
            const timestamp = new Date().getTime();
            const url = `/api/user/credits/${userData?._id}?t=${timestamp}`;
            const response = await axios.get(url);
            //  localStorage.setItem("userData", JSON.stringify(response.data.credits));
            console.log("Adnan main response is", response.data.credits)
            setCreditsCount(response.data.credits);
            await fetchrecentKeywords(userData?._id);

          } catch (error) {
            console.error('Error fetching credits', error);
          } finally {
            isFetching.current = false;
          }
        }
      };

      useEffect(() => {
        if (creditsCount === 0) {
          fetchCredits();
        }
      }, []);
      useEffect(() => {
        fetchrecentKeywords(userData?._id);
      }, []);

      
      

      // const getNextRenewalDate = () => {
      //   const lastRenewalDate = new Date(creditsCount.lastCreditRenewal);
      //   const nextRenewalDate = new Date(lastRenewalDate.setDate(lastRenewalDate.getDate() + 30));
    
      //   // Format the date as Jan 02, 2024, 11:23 PM
      //   return nextRenewalDate.toLocaleString('en-US', {
      //     month: 'short', 
      //     day: '2-digit', 
      //     year: 'numeric', 
      //     hour: '2-digit', 
      //     minute: '2-digit'
      //   });
      // };

      const getNextRenewalDate = () => {
        // const nextRenewalDate = new Date(creditsCount.createdAt);
       
        const createdAt = creditsCount.createdAt instanceof Date ? creditsCount.createdAt : new Date(creditsCount.createdAt);

  // Extract year from createdAt and add one
  const nextYear = createdAt.getFullYear() + 1;

  // Create a new Date object with next year, keeping month and day the same
  const nextRenewalDate = new Date(nextYear, createdAt.getMonth(), createdAt.getDate());

       
        return nextRenewalDate.toLocaleString('en-US', {
          month: 'short', 
          day: '2-digit', 
          year: 'numeric', 
          hour: '2-digit', 
          minute: '2-digit'
        });
      };


      const lifeTime = ["655e4ac2af5ec88f540e6ff2", "65671e501d93febeacf0f5ef", "65675b1c9aae3b322b57d7b1", "6584f59db968caf13695d8c4",
    "6595df49fe08ba5d070ed01a", "659432d95fe8068d8b05977a", "659428f25fe8068d8b059756", "6593ba1c11d91aed95a63f93", "6596509601f908df858fadeb",
  "6593384300cf718fce765252", "6593eded8f37902c10c153b4", "6592b4122293639a322d7725", "65909e7035bacd46af88236d", "659f5e6c0733c896dfdf1745", "659faaa4284e8c69695d8fd4", "659fe100fb40ea5941d59722",
"659fe100fb40ea5941d59722", "65971f6a6a5d7174f6ca2c9f", "65a001adbead6b1b3c47c6ab", "65a2718e940547ca882ab88f", "65a418172f3b7351612e203d", "65ae1096b297ce8102e70846",
"65ac8dab5767af6d37fff068", "65a7224bf2a4bb5e1d05d922", "65a51aeffefa9fcd91972434", "65a7224bf2a4bb5e1d05d922", "65ac8dab5767af6d37fff068", "65ae1096b297ce8102e70846", "65c81364773367852d1cf6ca", "65c81364773367852d1cf6ca", "65ec6c77645ced5fccd12c9b", "659a394881b8ee62227ffb33", 
"65f0a2350f01e5ff5dc1b8c6", "66099ab8b49d719e8f9235f7", "660a452eb49d719e8f932677", "661765a4426ce552a5bf6e65", "66176a3f426ce552a5bf6e84", "66178124426ce552a5bf6ed3", "6617f62769ab8c5518c8f9f1", "6591d3cc525ea61837a799e0", "6629096834db6c75cdd2b5e8", "662651467c5192ff8ea331fb",
"662253e3e02dfb4f977552b9", "65a3358f4e2858acf0cc6cb4", "661b7c8169ab8c5518caa8bb", "66178124426ce552a5bf6ed3", "6629848e34db6c75cdd50946", "668137003770dcb2aa8f6f6b", "65a3358f4e2858acf0cc6cb4", "664886c61ffae451314aa58b", "6629848e34db6c75cdd50946", "66176a3f426ce552a5bf6e84", "663a25a60fe8c929191e944d",
"663b83a87fcbf7e4d52271c1", "66945ba708ae47b4b7f53e20", "6629848e34db6c75cdd50946", "6695b125c11daa0b001626be", "663a25a60fe8c929191e944d", "66899dc73a3a28e47eddcaa0"
]; // kwamebarratt@gmail.com Subscription Cancelled

const {  updateKeywordData  } = useContext(KeywordContext); // Use the context
  const navigate = useNavigate();

// const handleNavigate = (item) => {
//   console.log(item, "item from handleNavigate")
//   const updatedItemData = {
//     ...item,
//     keywordData: item.keywordData.map((keywordItem, index) => ({
//       ...keywordItem,
//       id: keywordItem.id || `uuid-${index}`
//     }))
//   };


  
// console.log(updatedItemData, "updatedItemData from handleNavigate")
//   updateKeywordData(updatedItemData);
//   localStorage.setItem('temporaryKeywordData', JSON.stringify(updatedItemData));

//   let path = item.mainSeedKeyword;
//  // Check if the path is a full URL
//  if (path.startsWith('http://') || path.startsWith('https://')) {
//   // Create a URL object
//   const url = new URL(path);
//   // Extract hostname (domain name)
//   path = url.hostname;

//   // Remove 'www.' if present
//   if (path.startsWith('www.')) {
//       path = path.substring(4);
//   }
// }

//   // setKeywordData(item); // Setting the item in context
//   navigate(`/keyword-detail/${path}`);
// };

const handleNavigate = (item) => {
  console.log(item, "item from handleNavigate")
  const updatedItemData = {
    ...item,
    keywordData: item.keywordData.map((keywordItem, index) => ({
      ...keywordItem,
      id: keywordItem.id || `uuid-${index}`
    }))
  };

  // Slice the keywordData array to contain only the first 450 objects
  const slicedKeywordData = updatedItemData.keywordData.slice(0, 150);

  // Update the item with sliced keywordData
  const itemWithSlicedKeywordData = {
      ...updatedItemData,
      keywordData: slicedKeywordData
  };

  // Store the item with sliced keywordData in local storage
  localStorage.setItem('temporaryKeywordData', JSON.stringify(itemWithSlicedKeywordData));

 
  
console.log(updatedItemData, "updatedItemData from handleNavigate")
  updateKeywordData(updatedItemData);
  // localStorage.setItem('temporaryKeywordData', JSON.stringify(updatedItemData));

  let path = item.mainSeedKeyword;
 // Check if the path is a full URL
 if (path.startsWith('http://') || path.startsWith('https://')) {
  // Create a URL object
  const url = new URL(path);
  // Extract hostname (domain name)
  path = url.hostname;

  // Remove 'www.' if present
  if (path.startsWith('www.')) {
      path = path.substring(4);
  }
}

  // setKeywordData(item); // Setting the item in context
  navigate(`/keyword-detail/${path}`);
};
  return (
    <>
   
   <main className="flex flex-nowrap overflow-y-scroll">

<BarSide2 />

<div className="flex-grow">
  <div className="container mx-auto p-4">
    <div className="flex flex-wrap -mx-2">
      <div className="w-1/2 px-2 mb-4 ">
        <div className="border border-gray-200 bg-white rounded-lg p-4">
        <div>
            <div className="welcome-text">Welcome</div>
            <div className="user-name">{userData && userData.firstName && userData.lastName 
    ? `${userData.firstName} ${userData.lastName}` 
    : "User"}</div>
            <hr className="my-3"/>

            <div className="account-details">
            <div className="detail-row">
              <div className="label">Current Plan</div>
              <div className="value">
              {lifeTime.includes(userData?._id) ? "Lifetime Plan" : "Yearly Plan"}
              </div>
            </div>
            <div className="detail-row">
              <div className="label">Keyword Credits</div>
              <div className="detail-group">
                {/* <div className="value">{creditsCount.credits}</div>  */}
                <div className="value">
                  {/* <span class="material-symbols-outlined">all_inclusive</span> */}
                  <img src={infinateyLogo} className='homapaherecent' alt='List View' />
                  </div>
                {/* <div className="label"> / 3000</div> */}
              </div>
            </div>
            <div className="detail-row">
              <div className="label">Rank Tracker Credits</div>
              <div className="detail-group">
                <div className="value">{creditsCount.rankcredits}</div>
                <div className="label"> / 100</div>
              </div>
            </div>
            <div className="detail-row">
              <div className="label">Ads-On Credits</div>
              <div className="detail-group">
                <div className="value zeroAddOn">0</div>
                <div className="label"> / 0</div>
              </div>
            </div>
             {/* ... other detail rows ... */}
             <div className="detail-row">
              <div className="label">Credits Next Renewal</div>
              {/* <div className="value">Jan 02, 2024, 11:23 PM</div> */}
              <div className="value">{getNextRenewalDate()}</div>
            </div>
            <button type="button" className="btn btn-info w-100 btn-sm mt-3 .btn-info-custommain text-white">
              See plans
            </button>
            </div>
          </div>
        </div>
      </div>
      <div className="w-1/2 px-2 mb-4">
        <div className="border border-gray-200 bg-white rounded-lg p-4 customwidthrecent">
        <div >
            <div className="user-name keywords-name-list">Recent Keywords
            </div>
{/* keyword item */}

{recentKeyword?.slice(0, 5).map((item) => (
        <div className="item-container border-b border-gray-200 flex justify-between items-center  h-13 ml-[-0.55rem] p-[0.625rem] cursor-pointer list-keyword-hover" key={item?._id}>
          <div className="item-content flex items-center">
            <span className="material-symbols-outlined text-gray-500 text-lg">
              <img src={listLogo} className='homapaherecent' alt='List View' />
            </span>
            <div className="item-text ml-2.5 font-medium text-sm truncate max-w-[250px] item-text-main" onClick={() => handleNavigate(item)}>
              {item.mainSeedKeyword}
            </div>
          </div>
          <div className="item-actions flex items-center">
            <div className="item-count text-gray-500 mr-3.75">{item.keywordData.length}</div>
            <a className="icon-btn">
              <span className="material-symbols-outlined text-gray-500 text-lg">
              <img src={moreLogo} className='homapaherecent' alt='more View' />
              </span>
            </a>
          </div>
        </div>
      ))}

            </div>

        </div>
      </div>
      {/* Faq */}
      <div className='px-2 my-4'>
      <div className="box-border  bg-[#f8fafc] border border-[#e2e8f0] rounded-2xl w-full p-0 pb-6 flex flex-col gap-8">
            <div className="border-b border-[#e2e8f0]">
                <div className="flex flex-row items-center py-3 px-8 bg-white rounded-t-2xl">
                    <p className="text-2xl font-semibold">
                        <b className='helptitle'>Begin Your Journey</b> <span className='helptext'>| We're here to help!</span>
                    </p>
                </div>
            </div>
            <div className="flex flex-col md:flex-row md:ml-8 md:pb-8 gap-8">
                {/* FAQ Card */}
                <div className="bg-white border border-[#e2e8f0] rounded-2xl flex flex-col  gap-8 w-full md:w-1/3">
                    {/* <img src="/img/faqCard.10db34de.svg" alt="FAQ" className="z-10 rounded-t-2xl" /> */}
                    {/* <iframe width="100%" height="330px" className="z-10 rounded-2xl" src="https://www.youtube.com/embed/7qtpi4wAr-Q?si=Sn-xGc6ozPMIskQx" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> */}
                    <iframe width="100%" height="330px" className="z-10 rounded-2xl" src="https://www.youtube.com/embed/YploGr9ejCs?si=Vafo9rL9I_PebCt9" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    {/* <div className="flex flex-col gap-5 p-8">
                        <p className="font-medium">What plan suits me best?</p>
                        <p>
                            Of course, when thinking about plans the first question that comes to mind is: what plan is the one that would help the most in my field of work?<br /><br />
                            We’ve prepared a handy guide to help you understand what key tools do you need to use.
                        </p>
                        <a href="https://help.findthatlead.com/en/articles/6371332-new-prospector" target="_blank" rel="noopener noreferrer" className="inline-flex items-center font-medium text-blue-600">
                            Open Helpdesk
                        </a>
                    </div> */}

                </div>

                {/* Questions */}
                <div className="flex flex-col gap-5 w-full md:w-2/3 pr-4">
                    {/* Repeated question block */}
                    {/* <div className="flex flex-col gap-2">
                        <p>What is a correct email?</p>
                        
                    </div> */}
                      <Accordionmain />
                    {/* Repeat for other questions */}
                </div>
            </div>
        </div></div>
      {/* Faq */}
    </div>
  </div>
</div>

</main>

 
    </>
  )
}

export default Home