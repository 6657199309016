import React, {useState, useEffect} from 'react'
import BarSide2 from "../../BarSide2";
import { ToastContainer, toast } from 'react-toastify';
import { Row, Col, Typography, Table, Select, Input , Button } from 'antd';
import { BsCaretRight } from 'react-icons/bs';
import { Container } from "react-bootstrap";
import 'react-toastify/dist/ReactToastify.css';
import axios from "../../../components/Axios";
import { Link } from 'react-router-dom';


const {Option} = Select;

function ClusterTool() {

      const [keyword, setKeyword] = useState('');
      const [niche, setNiche] = useState('');
      const [languageCode, setLanguageCode] = useState('1000'); // Default to English
      const [countryCode, setCountryCode] = useState(2840); // Default to United States
      const [service, setService] = useState('openai');
      const [keywordTypes, setKeywordTypes] = useState('ai');
      const [model, setModel] = useState('gpt-4o');
      const [loading, setLoading] = useState(false);


    
      const handleKeywordChange = (e) => setKeyword(e.target.value);
      const handleNicheChange = (e) => setNiche(e.target.value);
      const handleLanguageCodeChange = (value) => setLanguageCode(value);
      const handleCountryCodeChange = (value) => setCountryCode(value);
      const handleServiceChange = (value) => setService(value);
      const handleKeywordTypeChange = (value) => setKeywordTypes(value);
      const handleModelChange = (value) => setModel(value);

      useEffect(() => {
        if (service === 'openai') {
          setModel('gpt-4o');
        } else if (service === 'gemini') {
          setModel('gemini-1.5-flash');
        }
      }, [service]);

      const openAiModels = [
        { value: 'gpt-3', label: 'GPT-3' },
        { value: 'gpt-3.5-turbo-0125', label: 'GPT-3.5 Turbo' },
        { value: 'gpt-4', label: 'GPT-4' },
        { value: 'gpt-4-0125-preview', label: 'GPT-4 Turbo' },
        { value: 'gpt-4o', label: 'GPT-4o' },
      ];
    
      const geminiModels = [
        { value: 'gemini-1.5-flash', label: 'Gemini 1.5 Flash' },
        { value: 'gemini-1.5-pro', label: 'Gemini 1.5 Pro' },
        { value: 'gemini-pro', label: 'Gemini 1.0 Pro' },
      ];
    
      var modelOptions = service === 'openai' ? openAiModels : geminiModels;

      const countries = [ {
        "code": "US",
        "criteriaid": 2840,
        "label": "United States"
       },
       {
        "code": "GB",
        "criteriaid": 2826,
        "label": "United Kingdom"
       },
       {
        "code": "AU",
        "criteriaid": 2036,
        "label": "Australia"
       },{
        "code": "DE",
        "criteriaid": 2276,
        "label": "Germany"
       },{
        "code": "CA",
        "criteriaid": 2124,
        "label": "Canada"
       },
        {
         "code": "AF",
         "criteriaid": 2004,
         "label": "Afghanistan"
        },
        {
         "code": "AL",
         "criteriaid": 2008,
         "label": "Albania"
        },
        {
         "code": "AQ",
         "criteriaid": 2010,
         "label": "Antarctica"
        },
        {
         "code": "DZ",
         "criteriaid": 2012,
         "label": "Algeria"
        },
        {
         "code": "AS",
         "criteriaid": 2016,
         "label": "American Samoa"
        },
        {
         "code": "AD",
         "criteriaid": 2020,
         "label": "Andorra"
        },
        {
         "code": "AO",
         "criteriaid": 2024,
         "label": "Angola"
        },
        {
         "code": "AG",
         "criteriaid": 2028,
         "label": "Antigua and Barbuda"
        },
        {
         "code": "AZ",
         "criteriaid": 2031,
         "label": "Azerbaijan"
        },
        {
         "code": "AR",
         "criteriaid": 2032,
         "label": "Argentina"
        },
        
        {
         "code": "AT",
         "criteriaid": 2040,
         "label": "Austria"
        },
        {
         "code": "BS",
         "criteriaid": 2044,
         "label": "The Bahamas"
        },
        {
         "code": "BH",
         "criteriaid": 2048,
         "label": "Bahrain"
        },
        {
         "code": "BD",
         "criteriaid": 2050,
         "label": "Bangladesh"
        },
        {
         "code": "AM",
         "criteriaid": 2051,
         "label": "Armenia"
        },
        {
         "code": "BB",
         "criteriaid": 2052,
         "label": "Barbados"
        },
        {
         "code": "BE",
         "criteriaid": 2056,
         "label": "Belgium"
        },
        {
         "code": "BT",
         "criteriaid": 2064,
         "label": "Bhutan"
        },
        {
         "code": "BO",
         "criteriaid": 2068,
         "label": "Bolivia"
        },
        {
         "code": "BA",
         "criteriaid": 2070,
         "label": "Bosnia and Herzegovina"
        },
        {
         "code": "BW",
         "criteriaid": 2072,
         "label": "Botswana"
        },
        {
         "code": "BR",
         "criteriaid": 2076,
         "label": "Brazil"
        },
        {
         "code": "BZ",
         "criteriaid": 2084,
         "label": "Belize"
        },
        {
         "code": "SB",
         "criteriaid": 2090,
         "label": "Solomon Islands"
        },
        {
         "code": "BN",
         "criteriaid": 2096,
         "label": "Brunei"
        },
        {
         "code": "BG",
         "criteriaid": 2100,
         "label": "Bulgaria"
        },
        {
         "code": "MM",
         "criteriaid": 2104,
         "label": "Myanmar (Burma)"
        },
        {
         "code": "BI",
         "criteriaid": 2108,
         "label": "Burundi"
        },
        {
         "code": "BY",
         "criteriaid": 2112,
         "label": "Belarus"
        },
        {
         "code": "KH",
         "criteriaid": 2116,
         "label": "Cambodia"
        },
        {
         "code": "CM",
         "criteriaid": 2120,
         "label": "Cameroon"
        },
        
        {
         "code": "CV",
         "criteriaid": 2132,
         "label": "Cape Verde"
        },
        {
         "code": "CF",
         "criteriaid": 2140,
         "label": "Central African Republic"
        },
        {
         "code": "LK",
         "criteriaid": 2144,
         "label": "Sri Lanka"
        },
        {
         "code": "TD",
         "criteriaid": 2148,
         "label": "Chad"
        },
        {
         "code": "CL",
         "criteriaid": 2152,
         "label": "Chile"
        },
        {
         "code": "CN",
         "criteriaid": 2156,
         "label": "China"
        },
        {
         "code": "CX",
         "criteriaid": 2162,
         "label": "Christmas Island"
        },
        {
         "code": "CC",
         "criteriaid": 2166,
         "label": "Cocos (Keeling) Islands"
        },
        {
         "code": "CO",
         "criteriaid": 2170,
         "label": "Colombia"
        },
        {
         "code": "KM",
         "criteriaid": 2174,
         "label": "Comoros"
        },
        {
         "code": "CG",
         "criteriaid": 2178,
         "label": "Republic of the Congo"
        },
        {
         "code": "CD",
         "criteriaid": 2180,
         "label": "Democratic Republic of the Congo"
        },
        {
         "code": "CK",
         "criteriaid": 2184,
         "label": "Cook Islands"
        },
        {
         "code": "CR",
         "criteriaid": 2188,
         "label": "Costa Rica"
        },
        {
         "code": "HR",
         "criteriaid": 2191,
         "label": "Croatia"
        },
        {
         "code": "CY",
         "criteriaid": 2196,
         "label": "Cyprus"
        },
        {
         "code": "CZ",
         "criteriaid": 2203,
         "label": "Czechia"
        },
        {
         "code": "BJ",
         "criteriaid": 2204,
         "label": "Benin"
        },
        {
         "code": "DK",
         "criteriaid": 2208,
         "label": "Denmark"
        },
        {
         "code": "DM",
         "criteriaid": 2212,
         "label": "Dominica"
        },
        {
         "code": "DO",
         "criteriaid": 2214,
         "label": "Dominican Republic"
        },
        {
         "code": "EC",
         "criteriaid": 2218,
         "label": "Ecuador"
        },
        {
         "code": "SV",
         "criteriaid": 2222,
         "label": "El Salvador"
        },
        {
         "code": "GQ",
         "criteriaid": 2226,
         "label": "Equatorial Guinea"
        },
        {
         "code": "ET",
         "criteriaid": 2231,
         "label": "Ethiopia"
        },
        {
         "code": "ER",
         "criteriaid": 2232,
         "label": "Eritrea"
        },
        {
         "code": "EE",
         "criteriaid": 2233,
         "label": "Estonia"
        },
        {
         "code": "GS",
         "criteriaid": 2239,
         "label": "South Georgia and the South Sandwich Islands"
        },
        {
         "code": "FJ",
         "criteriaid": 2242,
         "label": "Fiji"
        },
        {
         "code": "FI",
         "criteriaid": 2246,
         "label": "Finland"
        },
        {
         "code": "FR",
         "criteriaid": 2250,
         "label": "France"
        },
        {
         "code": "PF",
         "criteriaid": 2258,
         "label": "French Polynesia"
        },
        {
         "code": "TF",
         "criteriaid": 2260,
         "label": "French Southern and Antarctic Lands"
        },
        {
         "code": "DJ",
         "criteriaid": 2262,
         "label": "Djibouti"
        },
        {
         "code": "GA",
         "criteriaid": 2266,
         "label": "Gabon"
        },
        {
         "code": "GE",
         "criteriaid": 2268,
         "label": "Georgia"
        },
        {
         "code": "GM",
         "criteriaid": 2270,
         "label": "The Gambia"
        },
        
        {
         "code": "GH",
         "criteriaid": 2288,
         "label": "Ghana"
        },
        {
         "code": "KI",
         "criteriaid": 2296,
         "label": "Kiribati"
        },
        {
         "code": "GR",
         "criteriaid": 2300,
         "label": "Greece"
        },
        {
         "code": "GD",
         "criteriaid": 2308,
         "label": "Grenada"
        },
        {
         "code": "GU",
         "criteriaid": 2316,
         "label": "Guam"
        },
        {
         "code": "GT",
         "criteriaid": 2320,
         "label": "Guatemala"
        },
        {
         "code": "GN",
         "criteriaid": 2324,
         "label": "Guinea"
        },
        {
         "code": "GY",
         "criteriaid": 2328,
         "label": "Guyana"
        },
        {
         "code": "HT",
         "criteriaid": 2332,
         "label": "Haiti"
        },
        {
         "code": "HM",
         "criteriaid": 2334,
         "label": "Heard Island and McDonald Islands"
        },
        {
         "code": "VA",
         "criteriaid": 2336,
         "label": "Vatican City"
        },
        {
         "code": "HN",
         "criteriaid": 2340,
         "label": "Honduras"
        },
        {
         "code": "HU",
         "criteriaid": 2348,
         "label": "Hungary"
        },
        {
         "code": "IS",
         "criteriaid": 2352,
         "label": "Iceland"
        },
        {
         "code": "IN",
         "criteriaid": 2356,
         "label": "India"
        },
        {
         "code": "ID",
         "criteriaid": 2360,
         "label": "Indonesia"
        },
        {
         "code": "IQ",
         "criteriaid": 2368,
         "label": "Iraq"
        },
        {
         "code": "IE",
         "criteriaid": 2372,
         "label": "Ireland"
        },
        {
         "code": "IL",
         "criteriaid": 2376,
         "label": "Israel"
        },
        {
         "code": "IT",
         "criteriaid": 2380,
         "label": "Italy"
        },
        {
         "code": "CI",
         "criteriaid": 2384,
         "label": "Cote d'Ivoire"
        },
        {
         "code": "JM",
         "criteriaid": 2388,
         "label": "Jamaica"
        },
        {
         "code": "JP",
         "criteriaid": 2392,
         "label": "Japan"
        },
        {
         "code": "KZ",
         "criteriaid": 2398,
         "label": "Kazakhstan"
        },
        {
         "code": "JO",
         "criteriaid": 2400,
         "label": "Jordan"
        },
        {
         "code": "KE",
         "criteriaid": 2404,
         "label": "Kenya"
        },
        {
         "code": "KR",
         "criteriaid": 2410,
         "label": "South Korea"
        },
        {
         "code": "KW",
         "criteriaid": 2414,
         "label": "Kuwait"
        },
        {
         "code": "KG",
         "criteriaid": 2417,
         "label": "Kyrgyzstan"
        },
        {
         "code": "LA",
         "criteriaid": 2418,
         "label": "Laos"
        },
        {
         "code": "LB",
         "criteriaid": 2422,
         "label": "Lebanon"
        },
        {
         "code": "LS",
         "criteriaid": 2426,
         "label": "Lesotho"
        },
        {
         "code": "LV",
         "criteriaid": 2428,
         "label": "Latvia"
        },
        {
         "code": "LR",
         "criteriaid": 2430,
         "label": "Liberia"
        },
        {
         "code": "LY",
         "criteriaid": 2434,
         "label": "Libya"
        },
        {
         "code": "LI",
         "criteriaid": 2438,
         "label": "Liechtenstein"
        },
        {
         "code": "LT",
         "criteriaid": 2440,
         "label": "Lithuania"
        },
        {
         "code": "LU",
         "criteriaid": 2442,
         "label": "Luxembourg"
        },
        {
         "code": "MG",
         "criteriaid": 2450,
         "label": "Madagascar"
        },
        {
         "code": "MW",
         "criteriaid": 2454,
         "label": "Malawi"
        },
        {
         "code": "MY",
         "criteriaid": 2458,
         "label": "Malaysia"
        },
        {
         "code": "MV",
         "criteriaid": 2462,
         "label": "Maldives"
        },
        {
         "code": "ML",
         "criteriaid": 2466,
         "label": "Mali"
        },
        {
         "code": "MT",
         "criteriaid": 2470,
         "label": "Malta"
        },
        {
         "code": "MR",
         "criteriaid": 2478,
         "label": "Mauritania"
        },
        {
         "code": "MU",
         "criteriaid": 2480,
         "label": "Mauritius"
        },
        {
         "code": "MX",
         "criteriaid": 2484,
         "label": "Mexico"
        },
        {
         "code": "MC",
         "criteriaid": 2492,
         "label": "Monaco"
        },
        {
         "code": "MN",
         "criteriaid": 2496,
         "label": "Mongolia"
        },
        {
         "code": "MD",
         "criteriaid": 2498,
         "label": "Moldova"
        },
        {
         "code": "ME",
         "criteriaid": 2499,
         "label": "Montenegro"
        },
        {
         "code": "MA",
         "criteriaid": 2504,
         "label": "Morocco"
        },
        {
         "code": "MZ",
         "criteriaid": 2508,
         "label": "Mozambique"
        },
        {
         "code": "OM",
         "criteriaid": 2512,
         "label": "Oman"
        },
        {
         "code": "NA",
         "criteriaid": 2516,
         "label": "Namibia"
        },
        {
         "code": "NR",
         "criteriaid": 2520,
         "label": "Nauru"
        },
        {
         "code": "NP",
         "criteriaid": 2524,
         "label": "Nepal"
        },
        {
         "code": "NL",
         "criteriaid": 2528,
         "label": "Netherlands"
        },
        {
         "code": "CW",
         "criteriaid": 2531,
         "label": "Curacao"
        },
        {
         "code": "SX",
         "criteriaid": 2534,
         "label": "Sint Maarten"
        },
        {
         "code": "BQ",
         "criteriaid": 2535,
         "label": "Caribbean Netherlands"
        },
        {
         "code": "NC",
         "criteriaid": 2540,
         "label": "New Caledonia"
        },
        {
         "code": "VU",
         "criteriaid": 2548,
         "label": "Vanuatu"
        },
        {
         "code": "NZ",
         "criteriaid": 2554,
         "label": "New Zealand"
        },
        {
         "code": "NI",
         "criteriaid": 2558,
         "label": "Nicaragua"
        },
        {
         "code": "NE",
         "criteriaid": 2562,
         "label": "Niger"
        },
        {
         "code": "NG",
         "criteriaid": 2566,
         "label": "Nigeria"
        },
        {
         "code": "NU",
         "criteriaid": 2570,
         "label": "Niue"
        },
        {
         "code": "NF",
         "criteriaid": 2574,
         "label": "Norfolk Island"
        },
        {
         "code": "NO",
         "criteriaid": 2578,
         "label": "Norway"
        },
        {
         "code": "MP",
         "criteriaid": 2580,
         "label": "Northern Mariana Islands"
        },
        {
         "code": "UM",
         "criteriaid": 2581,
         "label": "United States Minor Outlying Islands"
        },
        {
         "code": "FM",
         "criteriaid": 2583,
         "label": "Federated States of Micronesia"
        },
        {
         "code": "MH",
         "criteriaid": 2584,
         "label": "Marshall Islands"
        },
        {
         "code": "PW",
         "criteriaid": 2585,
         "label": "Palau"
        },
        {
         "code": "PK",
         "criteriaid": 2586,
         "label": "Pakistan"
        },
        {
         "code": "PA",
         "criteriaid": 2591,
         "label": "Panama"
        },
        {
         "code": "PG",
         "criteriaid": 2598,
         "label": "Papua New Guinea"
        },
        {
         "code": "PY",
         "criteriaid": 2600,
         "label": "Paraguay"
        },
        {
         "code": "PE",
         "criteriaid": 2604,
         "label": "Peru"
        },
        {
         "code": "PH",
         "criteriaid": 2608,
         "label": "Philippines"
        },
        {
         "code": "PN",
         "criteriaid": 2612,
         "label": "Pitcairn Islands"
        },
        {
         "code": "PL",
         "criteriaid": 2616,
         "label": "Poland"
        },
        {
         "code": "PT",
         "criteriaid": 2620,
         "label": "Portugal"
        },
        {
         "code": "GW",
         "criteriaid": 2624,
         "label": "Guinea-Bissau"
        },
        {
         "code": "TL",
         "criteriaid": 2626,
         "label": "Timor-Leste"
        },
        {
         "code": "QA",
         "criteriaid": 2634,
         "label": "Qatar"
        },
        {
         "code": "RO",
         "criteriaid": 2642,
         "label": "Romania"
        },
        {
         "code": "RU",
         "criteriaid": 2643,
         "label": "Russia"
        },
        {
         "code": "RW",
         "criteriaid": 2646,
         "label": "Rwanda"
        },
        {
         "code": "SH",
         "criteriaid": 2654,
         "label": "Saint Helena, Ascension and Tristan da Cunha"
        },
        {
         "code": "KN",
         "criteriaid": 2659,
         "label": "Saint Kitts and Nevis"
        },
        {
         "code": "LC",
         "criteriaid": 2662,
         "label": "Saint Lucia"
        },
        {
         "code": "PM",
         "criteriaid": 2666,
         "label": "Saint Pierre and Miquelon"
        },
        {
         "code": "VC",
         "criteriaid": 2670,
         "label": "Saint Vincent and the Grenadines"
        },
        {
         "code": "SM",
         "criteriaid": 2674,
         "label": "San Marino"
        },
        {
         "code": "ST",
         "criteriaid": 2678,
         "label": "Sao Tome and Principe"
        },
        {
         "code": "SA",
         "criteriaid": 2682,
         "label": "Saudi Arabia"
        },
        {
         "code": "SN",
         "criteriaid": 2686,
         "label": "Senegal"
        },
        {
         "code": "RS",
         "criteriaid": 2688,
         "label": "Serbia"
        },
        {
         "code": "SC",
         "criteriaid": 2690,
         "label": "Seychelles"
        },
        {
         "code": "SL",
         "criteriaid": 2694,
         "label": "Sierra Leone"
        },
        {
         "code": "SG",
         "criteriaid": 2702,
         "label": "Singapore"
        },
        {
         "code": "SK",
         "criteriaid": 2703,
         "label": "Slovakia"
        },
        {
         "code": "VN",
         "criteriaid": 2704,
         "label": "Vietnam"
        },
        {
         "code": "SI",
         "criteriaid": 2705,
         "label": "Slovenia"
        },
        {
         "code": "SO",
         "criteriaid": 2706,
         "label": "Somalia"
        },
        {
         "code": "ZA",
         "criteriaid": 2710,
         "label": "South Africa"
        },
        {
         "code": "ZW",
         "criteriaid": 2716,
         "label": "Zimbabwe"
        },
        {
         "code": "ES",
         "criteriaid": 2724,
         "label": "Spain"
        },
        {
         "code": "SD",
         "criteriaid": 2736,
         "label": "Sudan"
        },
        {
         "code": "SR",
         "criteriaid": 2740,
         "label": "Suriname"
        },
        {
         "code": "SZ",
         "criteriaid": 2748,
         "label": "Eswatini"
        },
        {
         "code": "SE",
         "criteriaid": 2752,
         "label": "Sweden"
        },
        {
         "code": "CH",
         "criteriaid": 2756,
         "label": "Switzerland"
        },
        {
         "code": "TJ",
         "criteriaid": 2762,
         "label": "Tajikistan"
        },
        {
         "code": "TH",
         "criteriaid": 2764,
         "label": "Thailand"
        },
        {
         "code": "TG",
         "criteriaid": 2768,
         "label": "Togo"
        },
        {
         "code": "TK",
         "criteriaid": 2772,
         "label": "Tokelau"
        },
        {
         "code": "TO",
         "criteriaid": 2776,
         "label": "Tonga"
        },
        {
         "code": "TT",
         "criteriaid": 2780,
         "label": "Trinidad and Tobago"
        },
        {
         "code": "AE",
         "criteriaid": 2784,
         "label": "United Arab Emirates"
        },
        {
         "code": "TN",
         "criteriaid": 2788,
         "label": "Tunisia"
        },
        {
         "code": "TR",
         "criteriaid": 2792,
         "label": "Turkey"
        },
        {
         "code": "TM",
         "criteriaid": 2795,
         "label": "Turkmenistan"
        },
        {
         "code": "TV",
         "criteriaid": 2798,
         "label": "Tuvalu"
        },
        {
         "code": "UG",
         "criteriaid": 2800,
         "label": "Uganda"
        },
        {
         "code": "UA",
         "criteriaid": 2804,
         "label": "Ukraine"
        },
        {
         "code": "MK",
         "criteriaid": 2807,
         "label": "North Macedonia"
        },
        {
         "code": "EG",
         "criteriaid": 2818,
         "label": "Egypt"
        },
        {
         "code": "GG",
         "criteriaid": 2831,
         "label": "Guernsey"
        },
        {
         "code": "JE",
         "criteriaid": 2832,
         "label": "Jersey"
        },
        {
         "code": "TZ",
         "criteriaid": 2834,
         "label": "Tanzania"
        },
        {
         "code": "BF",
         "criteriaid": 2854,
         "label": "Burkina Faso"
        },
        {
         "code": "UY",
         "criteriaid": 2858,
         "label": "Uruguay"
        },
        {
         "code": "UZ",
         "criteriaid": 2860,
         "label": "Uzbekistan"
        },
        {
         "code": "VE",
         "criteriaid": 2862,
         "label": "Venezuela"
        },
        {
         "code": "WF",
         "criteriaid": 2876,
         "label": "Wallis and Futuna"
        },
        {
         "code": "WS",
         "criteriaid": 2882,
         "label": "Samoa"
        },
        {
         "code": "YE",
         "criteriaid": 2887,
         "label": "Yemen"
        },
        {
         "code": "ZM",
         "criteriaid": 2894,
         "label": "Zambia"
        }
       ];
       const languages = {
        1000: "English",
        1019: "Arabic",
        1056: "Bengali",
        1020: "Bulgarian",
        1038: "Catalan",
        1017: "Chinese (Simplified)",
        1018: "Chinese (Traditional)",
        1039: "Croatian",
        1021: "Czech",
        1009: "Danish",
        1010: "Dutch",
        1043: "Estonian",
        1042: "Filipino",
        1011: "Finnish",
        1002: "French",
        1001: "German",
        1022: "Greek",
        1072: "Gujarati",
        1027: "Hebrew",
        1023: "Hindi",
        1024: "Hungarian",
        1026: "Icelandic",
        1025: "Indonesian",
        1004: "Italian",
        1005: "Japanese",
        1086: "Kannada",
        1012: "Korean",
        1028: "Latvian",
        1029: "Lithuanian",
        1102: "Malay",
        1098: "Malayalam",
        1101: "Marathi",
        1013: "Norwegian",
        1064: "Persian",
        1030: "Polish",
        1014: "Portuguese",
        1032: "Romanian",
        1031: "Russian",
        1035: "Serbian",
        1033: "Slovak",
        1034: "Slovenian",
        1003: "Spanish",
        1015: "Swedish",
        1130: "Tamil",
        1131: "Telugu",
        1044: "Thai",
        1037: "Turkish",
        1036: "Ukrainian",
        1041: "Urdu",
        1040: "Vietnamese",
        // Add other languages as needed
      };
    
    const userDataString = localStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const [responseData, setResponseData] = useState([]); // State to hold response data


const handleSubmit = async () => {
    // Validation checks
    if (!keyword || !niche || !languageCode || !countryCode || !service || !model) {
      toast.error('Please fill in all required fields.');
      return;
    }

    const requestData = {
      keyword,
      niche,
      languageCode: Number(languageCode),
      countryCode,
      service,
      model,
      geminiModel: model,
      openaiapiKey: userData?.openaiApi,
      geminiapi: userData?.geminiApi,
      userId: userData?._id,
      keywordTypes
    };
console.log(requestData, "requestData")
    setLoading(true);
    try {
      const response = await axios.post('/cluster/generate', requestData);
      toast.success('Request successful!');
      console.log(response.data);
      setResponseData(response.data);
    } catch (error) {
      toast.error(`Error: ${error.response ? error.response.data.error : error.message}`);
    } finally {
      setLoading(false);
    }
  };
    

  const columns = [
    {
      title: 'Keyword',
      dataIndex: 'keyword',
      key: 'keyword',
      render: (text, record) => <Link to={`/keyword/${record._id}`} state={{ keywordData: record }}>{text}</Link>,
    },
    {
      title: 'Niche',
      dataIndex: 'niche',
      key: 'niche',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (text) => new Date(text).toLocaleString(),
    }
    // Add more columns as needed
  ];


  // useEffect(() => {
  //   const fetchData = async () => {
  //     const timestamp = new Date().getTime();
  //     try {
  //       const response = await axios.get(`/cluster/userdocs/${userData._id}?timestamp=${timestamp}`);
  //       setResponseData(response.data);
  //       console.log(response.data);
  //     } catch (error) {
  //       console.error('Error fetching user documents:', error);
  //       toast.error('Failed to fetch user documents.');
  //     }
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      const timestamp = new Date().getTime();
      
      // Display the loading toast and get its ID
      const toastId = toast.loading('Loading keywords...');
      
      try {
        const response = await axios.get(`/cluster/userdocs/${userData._id}?timestamp=${timestamp}`);
        setResponseData(response.data);
        console.log(response.data);
        
        // Update the toast to success
        toast.update(toastId, {
          render: 'Keywords loaded successfully!',
          type: 'success',
          isLoading: false,
          autoClose: 3000, // Auto close after 3 seconds
        });
      } catch (error) {
        console.error('Error fetching user documents:', error);
        
        // Update the toast to error
        toast.update(toastId, {
          render: 'Failed to fetch user documents.',
          type: 'error',
          isLoading: false,
          autoClose: 5000, // Auto close after 5 seconds
        });
      }
    };

    fetchData();
  }, []);




  return (
    <>
    <ToastContainer
      position="top-right"
      autoClose={10000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
    <main className="d-flex flex-nowrap">
      <BarSide2 />
      <Container>
        <div className="d-flex flex-column flex-grow-1 p-4">
          <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
            <div className="container mt-3">
              <div className="row">
                <div className="col-md-12">
                  <div className="ra-home border border-gray-300 bg-white rounded-lg">
                    <div className="d-flex justify-content-between">
                      <h4>Topical Mapping Tool</h4>
                      <Row align="middle" className="tutDiv">
                        <Col>
                          <BsCaretRight style={{ verticalAlign: 'middle' }} className="tutColor" />
                        </Col>
                        <Col>
                          <Typography.Text className="tutColor tutText">
                            <a className='colorNamea' href="https://www.youtube.com/watch?v=h_kMhbWHNyc" target="_blank" rel="noopener noreferrer">
                              VIDEO TUTORIAL
                            </a>
                          </Typography.Text>
                        </Col>
                      </Row>
                    </div>
                    <div className="keyword-discovery-container">
                      <Typography.Paragraph className="keyword-description">
                        Details About Cluster Tool
                      </Typography.Paragraph>
                    </div>
                    <div style={{ marginTop: '14px' }}>
                        {/* Inputs */}
                      <Row gutter={8}>
                      <Col span={6}>
        <Input
          placeholder="Enter keyword"
          value={keyword}
          onChange={handleKeywordChange}
        />
      </Col>
      <Col span={6}>
        <Input
          placeholder="Enter niche"
          value={niche}
          onChange={handleNicheChange}
        />
      </Col>
      <Col span={6}>
      <Select
      placeholder="Select language"
      value={languageCode}
      onChange={handleLanguageCodeChange}
      style={{ width: '100%' }}
    >
      {Object.keys(languages).map((criteriaid) => (
        <Option key={criteriaid} value={criteriaid}>
          {languages[criteriaid]}
        </Option>
      ))}
    </Select>
      </Col>
      <Col span={6}>
      <Select
      value={countryCode}
      onChange={handleCountryCodeChange}
      style={{ width: '100%' }}
    >
      {countries.map((country) => (
        <Option key={country.criteriaid} value={country.criteriaid}>
          {country.label}
        </Option>
      ))}
    </Select>
      </Col>
      <Col span={6}>
      <Select
          placeholder="Select service"
          value={service}
          onChange={handleServiceChange}
          style={{ width: '100%' }}
          className="mt-1"
        >
          <Option value="openai">OpenAI</Option>
          <Option value="gemini">Gemini</Option>
        </Select>
      </Col>
      <Col span={6}>
      <Select
          placeholder="Select model"
          value={model}
          onChange={handleModelChange}
          style={{ width: '100%' }}
          className="mt-1"
        >
          {modelOptions.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Col>
      <Col span={6}>
      <Select
          placeholder="Select Api"
          value={keywordTypes}
          onChange={handleKeywordTypeChange}
          style={{ width: '100%' }}
          className="mt-1"
        >
          <Option value="ai">Ai</Option>
          <Option value="googleadsapi">Google Ads Api</Option>
        </Select>
      </Col>
      <Col span={6}>
      <Button key="submit" className='mt-1' style={{width: '100%'}} loading={loading} onClick={handleSubmit}>
            Submit
          </Button>,
      </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
              {/* Table */}
              <div className="row">
                <div className="col-md-12">
                  <div className='mt-5'>
                  <Table dataSource={responseData} columns={columns} pagination={{ pageSize: 50 }} rowKey="_id" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </main>
  </>
  )
}

export default ClusterTool