import React, { useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Button, Input, Space } from 'antd';
import BarSide2 from '../../BarSide2';
import { Container } from 'react-bootstrap';
import { CSVLink } from 'react-csv'; 
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer } from 'react-toastify'; // For toast notifications
import 'react-toastify/dist/ReactToastify.css';
import { RiOpenaiFill } from "react-icons/ri";
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';


const KeywordDetails = () => {
  const location = useLocation();
  const { keywordData } = location.state;

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const [filteredInfo, setFilteredInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // Track selected row keys
  const [selectedRows, setSelectedRows] = useState([]); // Track selected row objects
  

  if (!keywordData) {
    return <div>No data available</div>;
  }

  console.log(keywordData, "keywordData"); // Ensure keywordData is logged for debugging

  // Flatten the data structure
  const flattenedData = keywordData.subtopics.flatMap((subtopic) =>
    subtopic.details.flatMap((detail) =>
      detail.googleAdsResults.map((result) => ({
        text: result.text,
        averageSearchVolume: result.keyword_idea_metrics?.avg_monthly_searches || '0',
        subtopic: detail.title,
        topic: subtopic.title,
      }))
    )
  );

  // Extract unique values for filtering
  const uniqueTopics = [...new Set(flattenedData.map((item) => item.topic))];
  const uniqueSubtopics = [...new Set(flattenedData.map((item) => item.subtopic))];

  // Create filter objects for each unique value
  const topicFilters = uniqueTopics.map((topic) => ({ text: topic, value: topic }));
  const subtopicFilters = uniqueSubtopics.map((subtopic) => ({ text: subtopic, value: subtopic }));

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };

  const handleChatGptClick = (record) => {
    // Prepare data
    const data = {
      keyword: record.text,
      subtopic: record.subtopic,
      topic: record.topic,
    };
  
    // Send message to content script
    window.postMessage(
      {
        source: 'react-app', // Unique identifier
        type: 'OPEN_CHATGPT',
        payload: data,
      },
      '*'
    );
  };

  // Function to handle search functionality
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm(); 
    setSearchText(selectedKeys[0]); 
    setSearchedColumn(dataIndex); 
    setFilteredInfo((prevFilters) => ({
      ...prevFilters,
      [dataIndex]: selectedKeys[0], // Set the search filter in the filteredInfo
    }));
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            // type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button type="link" size="small" onClick={() => close()}>close</Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1677ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    ) : (
      text
    ),
  });
  
  
  

  // Define columns for the main table
  const columns = [
    {
      title: 'Keyword',
      dataIndex: 'text',
      key: 'text',
      width: 200,
      ...getColumnSearchProps('text'),
      onCell: () => {
        return {
          style: {
            paddingLeft: '30px',
          },
        };
      },
      onHeaderCell: () => {
        return {
          style: {
            paddingLeft: '30px',
          },
        };
      },
    },{
      title: 'Average Monthly Searches', // New Column
      dataIndex: 'averageSearchVolume',
      key: 'averageSearchVolume',
      width: 200,
      sorter: (a, b) => Number(a.averageSearchVolume) - Number(b.averageSearchVolume),
      render: (text) => text !== undefined ? text : 'N/A', // Handle missing data
    },
    {
      title: 'Subtopic',
      dataIndex: 'subtopic',
      key: 'subtopic',
      width: 200,
      filters: subtopicFilters,
      filteredValue: filteredInfo.subtopic || null,
      onFilter: (value, record) => record.subtopic.includes(value), 
    },
    {
      title: '', // ChatGPT Icon Column
      key: 'chatgpt',
      width: 50,
      render: (text, record) => (
        <Button
          type="link"
          icon={<RiOpenaiFill size={24} color="black" />}
          onClick={() => handleChatGptClick(record)}
          className="chatgpt-button"
        />
      ),
    },
    {
      title: 'Topic',
      dataIndex: 'topic',
      key: 'topic',
      width: 200,
      filters: topicFilters,
      filteredValue: filteredInfo.topic || null,
      onFilter: (value, record) => record.topic.includes(value),
    },
  ];

  const headers = [
    { label: 'Keyword', key: 'text' },
    { label: 'Average Monthly Searches', key: 'averageSearchVolume' },
    { label: 'Subtopic', key: 'subtopic' },
    { label: 'Topic', key: 'topic' },
  ];

  const filteredData = flattenedData.filter((item) => {
    let isMatch = true;
    
    // Apply Topic filter
    if (filteredInfo.topic && filteredInfo.topic.length > 0) {
      isMatch = isMatch && filteredInfo.topic.includes(item.topic);
    }
    
    // Apply Subtopic filter
    if (filteredInfo.subtopic && filteredInfo.subtopic.length > 0) {
      isMatch = isMatch && filteredInfo.subtopic.includes(item.subtopic);
    }

    // Apply Search filter on 'text' (Keyword column)
  if (filteredInfo.text && filteredInfo.text.length > 0) {
    isMatch = isMatch && item.text.toLowerCase().includes(filteredInfo.text.toLowerCase());
  }
    
    return isMatch;
  });
  


  const rowSelection = {
    selectedRowKeys, // Controlled selected row keys
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
      setSelectedRows(selectedRows);
      console.log(`selectedRowKeys: ${selectedKeys}`, 'selectedRows: ', selectedRows);
    },
  };
  

const keywordsToCopy = selectedRows.map(row => row.text).join('\n'); // Use '\n' for new lines or ', ' for commas



  return (
    <>
     <ToastContainer 
        position="top-right" 
        autoClose={5000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
      />
<main className="d-flex flex-nowrap">
  <BarSide2 />
  <Container>
    <div className="d-flex flex-column flex-grow-1 p-4">
      <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-12">
              <div className="ra-home border border-gray-300 bg-white rounded-lg">
                <div className="d-flex justify-content-between">
               <h3>{keywordData.keyword}</h3> 
               <div style={{ marginBottom: '6px' }}>
<CopyToClipboard 
  text={keywordsToCopy}
  onCopy={() => {
    if (keywordsToCopy.length > 0) {
      toast.success('Keywords copied to clipboard!');
    } else {
      toast.info('No keywords selected to copy.');
    }
  }}
>
  <Button type="primary" style={{ backgroundColor: "#1677ff", marginRight: '8px'}}>
    Copy To Clipboard
  </Button>
</CopyToClipboard>

  <CSVLink data={filteredData} headers={headers} filename="keywords.csv"  style={{ textDecoration: 'none' }}>
    <Button type="primary" style={{ backgroundColor: "#1677ff"}}>Export Data</Button>
  </CSVLink>
</div>
                </div> {/* Close justify-content-between div */}
              </div> {/* Close ra-home div */}
            </div> {/* Close col-md-12 div */}
          </div> {/* Close row div */}





    <div className='row'>
    <div className="container mt-3">
          <div className="row">
            <div className="col-md-12">
            <div className="ra-home border border-gray-300 bg-white rounded-lg">
            <div className="d-flex justify-content-between">
            <Table
                          dataSource={filteredData}
                          columns={columns}
                          // rowKey="text"
                          rowKey="text" // Ensure 'key' is unique; adjust if necessary
                          rowSelection={rowSelection} // Add rowSelection
                          // pagination={false}
                          pagination={{ pageSize: 1500 }}
                          scroll={{ x: 'max-content' }}
                          onChange={handleChange}
                          style={{ width: '100%' }}
                        />

              </div></div></div></div></div>
    </div>


        </div> {/* Close container div */}
        
      </div> {/* Close auto overflow div */}

    {/*  */} 
   

    </div> {/* Close flex-column div */}
  </Container> {/* Close Container */}
</main> {/* Close main */}
    </>
  );
};

export default KeywordDetails;
